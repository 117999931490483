<template>
    <form action @submit.prevent="actions[type]">
        <div class="d-flex flex-column justify-content-between text-dark-gray validate-form__size" style="gap: 43px;">
            <div class="image-size mx-auto">
                <img src="/img/login/icon-user.png" alt="" class="h-100 w-100 rounded-circle bg-white shadow-inner obj-none">
            </div>
            <div class="d-flex flex-column" style="gap: 43px;">
                <ValidationObserver ref="validator">
                    <ValidationProvider v-slot="{ errors }" name="usuario o correo electrónico" rules="required">
                        <div class="w-80 mx-auto">
                            <div class="text-center mb-2">
                                <p>Ingrese su {{placeholder[type]}}</p>
                            </div>
                            <input 
                            type="text" 
                            v-model="username" 
                            class="shadow-inner outline-none w-100 border-0 br-8 px-3 input__height"
                            >
                            <vee-error :text="errors[0] || emailError"/>
                        </div>
                    </ValidationProvider>
                </ValidationObserver>
                <div class="d-flex justify-content-center custom__mb">
                    <button class="btn btn-general px-5 f-600" type="submit">Aceptar</button>
                </div>
            </div>
            <div class="d-flex flex-column text-center w-fit-content mx-auto">

                <el-button 
                type="text" 
                class="text-dark-gray underline f-18" 
                @click="$router.push({ name: 'login' })"
                >
                    Iniciar sesión
                </el-button>

                <el-button 
                v-if="type == 'usuario'" 
                type="text" 
                class="text-dark-gray underline f-18 ml-0" 
                @click="$router.push({ name: 'recover', params: {tipo: 'clave'} })"
                >
                    ¿Olvidó su contraseña?
                </el-button>

                <el-button 
                v-if="type == 'clave'" 
                type="text" 
                class="text-dark-gray underline f-18 ml-0" 
                @click="$router.push({ name: 'recover', params: {tipo: 'usuario'} })"
                >
                    ¿Olvidó su usuario?
                </el-button>

            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    middleware: 'guest',
    layout:'login',
    data(){
        return {
            username: '',
            emailError: '',
            placeholder: {
                clave: 'correo o usuario',
                usuario: 'correo'
            },
            actions: {
                clave: this.sendCode,
                usuario: this.sendUser
            }
        }
    },
    computed:{
        ...mapGetters({
            email: 'auth/email'
        }),
        type(){
            return this.$route.params.tipo;
        }
    },
    methods:{
        sendCode(){
            this.$try(async() => {
                const valid = await this.$refs.validator.validate();
                if(!valid) return;

                const data = await this.$store.dispatch('auth/RECOVER_PASSWORD', { username: this.username });

                if(data?.error) return this.emailError = data.error;

                if(!this.email) return this.emailError = "El correo no ha sido encontrado";

                const [emailDirection, emailProvider] = this.email.split('@');

                if(emailDirection.length < 6) return this.emailError = "El correo es muy corto";

                const hiddenEmailDirection = emailDirection.split('').fill('*',2, -2).join('');
                const hiddenEmail = `${hiddenEmailDirection}@${emailProvider}`;
                this.$store.dispatch('auth/HIDE_EMAIL', hiddenEmail);
                
                this.$router.push({ name: 'validate.code' });

            })
        },
        sendUser(){
            this.$try(async() => {
                const valid = await this.$refs.validator.validate();
                if(!valid) return;

                const data = await this.$store.dispatch('auth/RECOVER_USERNAME', { email: this.username });

                if(data?.error) return this.emailError = data.error;

                this.$router.push({ name: 'sended.username', params: { email: this.username} });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.custom__mb{
    margin-bottom: 50px !important;
}
</style>